<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>發票審核列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">發票審核列表</h4>
      <div class="row my-3">
        <div class="col-12 col-xl-6">
          <b-form inline>
            <b-form-select
              v-model="filter.type"
              :options="types"
              @change="fetchInvoiceLogs"
            ></b-form-select>
            <b-form-select
              v-model="filter.status"
              :options="statuses"
              @change="fetchInvoiceLogs"
              class="ml-2"
            ></b-form-select>
          </b-form>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="invoiceLogs"
            :fields="fields"
            :busy="showLoading"
            @sort-changed="handleSort"
          >
            <template #cell(image)="data">
              <b-img
                v-if="data.item.image"
                :src="data.item.image.url"
                fluid
                thumbnail
                style="max-width: 100px; max-height: 100px; cursor: pointer;"
                @click="showImage(data.item.image.url)"
              ></b-img>
            </template>
            <template #cell(action)="data">
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_VERIFY)"
                variant="inverse-primary"
                :to="{ name: 'InvoiceLogVerify', params: { invoiceLogId: data.item.id } }"
              >
                審核
              </b-button>
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_MODIFY)"
                variant="inverse-warning"
                :to="{ name: 'InvoiceLogEdit', params: { invoiceLogId: data.item.id } }"
              >
                編輯
              </b-button>
              <b-button
                v-if="$permissions.has($permissions.consts.INVOICE_LOG_DELETE)"
                variant="inverse-danger"
                @click="handleDelete(data.item.id)"
              >
                刪除
              </b-button>
            </template>
          </b-table>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          @change="fetchInvoiceLogs"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import invoiceLogApi from "@/apis/invoice-log";
import moment from "moment";
import * as consts from '@/consts'

export default {
  data() {
    return {
      types: [
        { value: null, text: '全部類型' },
        { value: consts.INVOICE_LOG_TYPES.ELECTRONIC, text: '電子發票' },
        { value: consts.INVOICE_LOG_TYPES.TRADITIONAL, text: '傳統發票' },
      ],
      statuses: [
        { value: null, text: '全部狀態' },
        { value: consts.INVOICE_LOG_STATUS.INIT, text: '等待處理' },
        { value: consts.INVOICE_LOG_STATUS.E_VERIFY, text: '發票驗證中' },
        { value: consts.INVOICE_LOG_STATUS.E_VALID, text: '發票已處理' },
        { value: consts.INVOICE_LOG_STATUS.E_INVALID, text: '發票不存在或已作廢' },
        { value: consts.INVOICE_LOG_STATUS.T_VERIFY, text: '發票審核中 ' },
        { value: consts.INVOICE_LOG_STATUS.T_VALID, text: '發票已審核' },
        { value: consts.INVOICE_LOG_STATUS.T_INVALID, text: '發票不合格' },
      ],
      filter: {
        type: null,
        status: consts.INVOICE_LOG_STATUS.INIT,
        sort_by: 'id',
        sort_desc: 'desc',
      },
      showLoading: false,
      fields: [
        { key: "image", label: "照片" },
        { key: "no", label: "發票字軌" },
        {
          key: "issued_at",
          label: "開立時間" ,
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD') : '';
          },
        },
        { key: "random", label: "隨機碼" },
        {
          key: "customer",
          label: "會員",
          sortable: true,
          formatter: (value) => {
            return value ? value.name : '';
          },
        },
        {
          key: "status",
          label: "狀態",
          sortable: true,
          formatter: (value) => {
            return this.statuses.find((item) => item.value === value).text
          },
        },
        {
          key: "type",
          label: "類型",
          sortable: true,
          formatter: (value) => {
            return this.types.find((item) => item.value === value).text
          },
        },
        {
          key: "created_at",
          label: "上傳日期",
          sortable: true,
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD hh:mm:ss') : '';
          }
        },
        { key: "action", label: "操作" },
      ],
      invoiceLogs: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.fetchInvoiceLogs();
    },
    async fetchInvoiceLogs() {
      this.showLoading = true
      await this.doFetchInvoiceLogs()
      this.showLoading = false
    },
    async doFetchInvoiceLogs() {
      try {
        const { data } = await invoiceLogApi.list({
          ...this.filter,
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.invoiceLogs = data.data
        this.totalRows = data.meta.total;
      } catch (e) {
        console.log(e);
      }
    },
    showImage(url) {
      window.open(url, '_blank')
    },
    handleSort(ctx) {
      this.filter.sort_by = ctx.sortBy
      this.filter.sort_desc = ctx.sortDesc ? 'desc' : 'asc'
      this.fetchInvoiceLogs()
    },
    async handleDelete(invoiceLogId) {
      this.showLoading = true
      await this.doHandleDelete(invoiceLogId)
      this.showLoading = false
    },
    async doHandleDelete(invoiceLogId) {
      try {
        await invoiceLogApi.deleteInvoiceLog(invoiceLogId)
        this.$swal.fire({ icon: 'success', title: '刪除成功' })
        this.fetchInvoiceLogs()
      } catch (e) {
        console.log(e)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn + .btn {
  margin-left: .5rem;
}
</style>
