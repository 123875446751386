import https from "./https";
import store from "@/store";

const invoiceLog = {
  list(params) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/invoice-logs`,
      { params }
    );
  },

  deleteInvoiceLog(id) {
    const organization = store.state.general.organization;
    return https.delete(
      `/admin/organizations/${organization.id}/invoice-logs/${id}`
    );
  },

  getInvoiceLog(id) {
    const organization = store.state.general.organization;
    return https.get(
      `/admin/organizations/${organization.id}/invoice-logs/${id}`
    );
  },

  updateInvoiceLog(id, data) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/invoice-logs/${id}`,
      data
    );
  },

  updateInvoiceLogStatus(id, data) {
    const organization = store.state.general.organization;
    return https.put(
      `/admin/organizations/${organization.id}/invoice-logs/${id}/status`,
      data
    );
  },
};

export default invoiceLog;
